import React from "react";

export default function Skills() {
  const skStyle = {
    backgroundColor: 'black', 
    fontFamily: 'Josefin Slab',
    color: 'white'
  };
    return (
        <section id="skills" style={skStyle} >

          <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-left">
            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-left text-left">
            <h1 style={{fontFamily: 'cursive', textDecorationLine: 'underline'}}> Skills </h1>

          <div >
            <div >    
              
                    <h2 class="topic" style={{textDecorationLine: 'underline'}}>Data Visualization/Engineering</h2>
                    <ul>
                        <li> AWS </li>
                        <li> APIs</li>
                        <li> ETLs</li>
                        <li> Forecast Pro 100 </li>
                        <li> IBM SPSS Statistics </li>
                        <li> Microsoft Azure </li>
                        <li> Microsoft Excel </li>
                        <li> Modeling Algorithms</li>
                        <li> Model Management</li>
                        <li> PowerBI </li>
                        <li> Tableau </li>

                        
                    </ul>
            </div>

            <div >    
              
                    <h2 class="topic" style={{textDecorationLine: 'underline'}}>Hardware Design and Embedded Systems</h2>
                    <ul>
                        <li> FPGA </li>
                        <li> Microcontrollers </li>
                        <li> Raspberry Pi </li>
                        <li> Verilog </li>
                        <li> VHDL </li>
                        

  
                    </ul>
            </div>

            <div >
            <h2 class="topic" style={{textDecorationLine: 'underline'}}>Tools and Framework</h2>
                    <ul>
                        <li> AWS </li>
                        <li> Databases (PostgreSQL, MySQL, SQLLite, MongoDB) </li>
                        <li> Generative AI </li>
                        <li> Google Colab </li>
                        <li> Jupyter Notebook </li>
                        <li> Microsoft </li>
                        <li> pgAdmin </li>
                        <li> Python (Django, NumPy, Pandas, PyTorch, Tensorflow) </li>
                        <li> RStudio </li>
                        <li> Virtual Machines (AWS, Azure, Google Cloud, VirtualBox) </li>
                        <li> Visual Studio Code  </li>
                        <li> XCode  </li>

                    </ul>
            </div>

            <div >
            <h2 class="topic" style={{textDecorationLine: 'underline'}}>Programming Languages</h2>
                    <ul>
                        <li> C/C++ </li>
                        <li> HTML/CSS </li>
                        <li> Javascript (Express, Node, React, React Native, Vue) </li>
                        <li> Java </li>
                        <li> MATLAB </li>
                        <li> Python </li>
                        <li> R </li>
                    </ul>
            </div>
            </div>
          </div>
          </div>
      </section>
    );
};