import React from "react";

export default function Project() {
  const pjStyle = {
    backgroundColor: 'black', 
    fontFamily: 'Josefin Slab',
    color: 'white'
  };
  
  return (
    
    <section id="project" style = {pjStyle}>

      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-left">

      <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-left text-left">
      <h1 style={{fontFamily: 'cursive', textDecorationLine: 'underline'}}> Projects and Research </h1>
      <h2 class="project"> Real-Time Audio and Language Processing </h2>
            
            <h5 class="year"> Winter 2024/25 </h5>
            <p class="background"> Emotion Recognition in Music</p>
            <ul>
              <li> Developed a system to classify emotions (positive, negative, neutral) from streamed audio in real time. </li>
              <li> Extracted features like tempo, pitch, and spectral contrast using LibROSA. </li>
              <li> Created a mathematical formula based on the extracted features to help determine the song's emotion. </li>
              <li> Integrated ReSpeaker HAT and Raspberry Pi for high-quality, noise-suppressed audio input.</li>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/QlCLvyS1m_A?si=HkcE7SBtypIj-f9V" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </ul>

            <h2 class="project">Information Systems Capstone Project</h2>
            <h5 class="year"> Spring 2024 </h5>
            <p class="background"> House Market Prices Analytics – Phase 02 Report </p>
            <ul>
              <li> Spearheaded a comprehensive analysis of U.S. housing market trends focusing on the impact of federal interest rates and property features on housing prices using the AMES, Zillow and Redfin datasets. </li>
              <li> Utilized advanced statistical and machine learning methods including multiple linear regression and ANOVA to identify key predictors of real estate values and their implications on market dynamics. </li>
              <li> Developed predictive models to assess the influence of bedrooms, bathrooms, and square footage on house and land prices, achieving significant insights with high explanatory power. </li>
              <li> Utilized a variety of tools including Python, Excel, Google Colab, and Jupyter Notebook for data manipulation, visualization, and predictive modeling. </li>
              <li> Contributed actionable insights for real estate stakeholders to inform investment strategies and policy formulation, backed by robust statistical evidence and detailed data analysis. </li>

            </ul>

            <h2 class="project">Database Systems Senior Design Project</h2>
            <h5 class="year"> Fall 2020 </h5>
            <p class="background"> Background: For our senior design, we were tasked with designing a Youtube-like service.</p>
            <ul>
              <li> Designed an ER-model to provide a template for the database to follow </li>
              <li> Utilized the MERN Stack for the full stack implementation </li>
              <li> Implemented the model and schema for the service channel using back end </li>
              <li> Connected the server to the front end </li>
              
            </ul>
         

            <h2 class="project">Database Management Systems</h2>
            <h5 class="year"> Winter 2020 </h5>
            <p class="background"> Background: I modeled and created my own variation of an airline management system.</p>
            <ul>
              <li> Designed a logical model of the database with the ER-model </li>
              <li> Translated the ER design into PostgreSQL relational database schema </li>
              <li> Developed a client application with Java Database Connector (JDBC) </li>
              <li> Used the client application to support specific functionality and queries for the online booking system </li>
              
            </ul>
      </div>
      </div>
    </section>
  );
}